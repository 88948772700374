import React, { Fragment } from "react";
import { Button, Checkbox, Form, Input, message, Row, Col, Layout, Alert } from "antd";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// import { IconFont } from "util/Utils";
// import { Trans, withI18n } from "@lingui/react";
import cssStyle from "./style.less";
import styles from "./index.less";
import {
  hideAuthMessage,
  showAuthLoader,
  hideAuthLoader,
  userFacebookSignIn,
  userGithubSignIn,
  userGoogleSignIn,
  showAuthMessage,
  userSignIn,
  fetchUser,
  userTwitterSignIn,
  clearUserData,
  userMicrosoftSignIn,
  userResetPassword,
  userSignInSuccess,
  findUserSuccess,
  userOtpSignIn
} from "./../../appRedux/actions/Auth";

import { hideMessage } from "appRedux/actions/Training";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "components/CircularProgress/index";
import { microsoftProvider } from "../../util/microsoft";

import socket from "./../../util/socket";
import rsaWrapper from "./../../util/rsa-wrapper";
import aesWrapper from "./../../util/aes-wrapper";
// import { CLIENT_PRIVATE_KEY } from "./../../util/config";
// import /home/user/Desktop/OhPhish/lms.ohphish.com/src/routes/profile/model.js
//src/routes/profile/model.js
import ResetPasswordModel from "./../model";
// import ResetPasswordModel from "../../routes/profile/model.js"
import "./../../assets/css/uikit.css";
import "./../../assets/css/main.css";
import "./../../assets/css/fontawesome.css";

import { IconFont } from "util/Utils";
import store from "store";
import { getSubdomain } from "../../util/Utils";
// import {Cookies} from 'js-cookie'
import $ from "jquery";
const fingerprintjs2 = require("fingerprintjs2");
const cookie = require("js-cookie");

const FormItem = Form.Item;

const { Footer } = Layout;
class SignIn extends React.Component {
  state = {
    userData: null,
    connection: false,
    remember: "",
    loader: true,
    checkTimer: true,
  };
  handleSubmit = (e) => { 
    localStorage.removeItem("loginAuthMethod");
    message.destroy();
    this.props.hideAuthMessage();
    this.props.hideMessage();
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      console.log("AESKey", this.AESKey);
      // console.log({values})
      if (!err && this.AESKey && values.remember) {
        this.props.showAuthLoader();
        localStorage.setItem("AESKey", this.AESKey);
        aesWrapper.encryptMessage(this.AESKey, values.password).then((encryptedPassword) => {
          values.password = encryptedPassword;
          this.setState({ values }, function() {
            this.props.userSignIn(values);
          });
        });
      } else if (!values.remember) {
        this.setState({ remember: "" });
        this.props.showAuthMessage({ error: "Accept Terms & Condition" });
      }
    });
  };

  onClick = (type) => (e) => {
    switch (type) {
      case "plain":
        break;
      case "microsoft":
        break;
      default:
    }
  };

  handleCheckBox = (e) => {
    message.destroy();
    this.props.hideAuthMessage();
    this.props.hideMessage();
    if (e.target.checked) {
      this.setState({
        remember: true,
      });
    } else {
      this.setState({
        remember: "",
      });
    }
  };
  messageDestroy = (e) => {
    message.destroy();
    this.props.hideAuthMessage();
    this.props.hideMessage();
  };
  onNextClick = (e) => {
    localStorage.removeItem("loginAuthMethod");
    message.destroy();
    this.props.hideAuthMessage();
    this.props.hideMessage();
    this.props.form.validateFields((err, values) => {
      if (!err && values.remember) {
        message.destroy();
        this.props.showAuthLoader();
        this.props.fetchUser(values);
      } else {
        message.destroy();
        // this.setState({ remember: "" });
        this.props.showAuthMessage({ error: "Please enter a valid E-mail" });
      }
    });
  };
  onLoginWithMicrosoftClick = (e) => {
    localStorage.removeItem("loginAuthMethod");
    this.props.showAuthLoader();
    message.destroy();
    this.props.hideAuthMessage();
    this.props.hideMessage();
    var clientId = "a589d78c-1341-4ba0-8498-409706392dc4";
    const { tenantId, email, organisationId } = this.state.userData;
    if(tenantId){
      microsoftProvider.setTenantId(tenantId);
    }else{
      microsoftProvider.setTenantId("common");
    }
    
    
    if(organisationId == '660509c1fec3f24786ac0f0e'){
      clientId = '089e9b8a-f0b3-4288-b968-7197cdfc6d6a';
    }
    microsoftProvider.setClientId(clientId);
    microsoftProvider.setHintEmail(email);
    this.props.userMicrosoftSignIn(microsoftProvider, { tenantId, email, organisationId, microsoftX509Certificate: this.state.userData.microsoftX509Certificate, x509Certificate: this.state.userData.x509Certificate });
  };

  onLoginWithOtp = (e) => {
    this.props.form.validateFields((err, values) => {
      localStorage.removeItem("loginAuthMethod");
      // this.setState({
      //   googleLogin: true
      // }) 
      message.destroy();
      const { tempToken } = this.state.userData;
      values.tempToken = tempToken;
      this.props.hideAuthMessage();
      this.props.hideMessage();
      this.props.showAuthLoader();
      this.props.userOtpSignIn(values);
    })
  };

  onLoginWithGoogleClick = (e) => {
    this.props.form.validateFields((err, values) => {
      localStorage.removeItem("loginAuthMethod");
      this.setState({
        googleLogin: true
      })
      message.destroy();
      this.props.hideAuthMessage();
      this.props.hideMessage();
      this.props.showAuthLoader();
      this.props.userGoogleSignIn({...values, googleIdpId: this.state.userData.googleIdpId});
    })
  };

  componentWillMount() {
    window.scrollTo(0, 0);
    message.destroy();
    this.props.hideAuthMessage();
    this.props.hideMessage();
    this.props.hideAuthLoader();
    this.socketConnect();
    const loginAuthMethod = localStorage.getItem("loginAuthMethod");
    const userData = JSON.parse(loginAuthMethod);
    localStorage.removeItem("loginAuthMethod");
    this.setState({ userData: userData,remember: true})
    // var self = this;
    // var timer = setTimeout(() => {
    //   self.setState({ checkTimer: true });
    // }, 300);
    // clearTimeout(timer)
  }
  componentDidMount() {
    message.destroy();
    this.props.hideAuthMessage();
    this.props.hideMessage();
    this.props.hideAuthLoader();
    const loginAuthMethod = localStorage.getItem("loginAuthMethod");
    const userData = JSON.parse(loginAuthMethod);
    this.setState({ userData: userData, remember: true})
    console.log("-----------loginAuthMethod------componentDidMount------------",this.props.userData)
    var self = this;
    document.addEventListener("bothUser", function(e) {
      console.log({ e }); // Prints "Example of an event"
      self.setState({
        isBothUser: e.detail.isBothUser,
        loader: false,
      });
    });
    document.addEventListener("login", function(e) {
      console.log({ e }, "login");
      if (e.detail.user.trainee) {
        self.props.history.push("/trainee");
      } else {
        self.props.history.push("/main/dashboard/crm");
      }
    });
    if (this.props.userData !== null) {
      this.setState({
        userData: this.props.userData,
      });
      this.props.clearUserData();
    }
    document.addEventListener("loginAuthMethod", function(e) {
      console.log({ e }, "loginAuthMethod");
      // store.remove('loginAuthMethod');
      localStorage.setItem("loginAuthMethod", JSON.stringify(e.detail.userAuth));
      self.setState({
        userData: e.detail.userAuth,
        remember: true,
      });
      
      console.log({userData:e.detail.userAuth})
      // self.props.findUserSuccess(e.detail.userData) 
    });
    document.addEventListener("setPassword", function(e) {
      console.log({ e }, "setPassword");

      console.log("e.detail.user", e.detail.user);
     
      self.setState({
        resetPassword: true,
        loader: false,
        isTrainee: e.detail.user.trainee,
        accessToken: e.detail.user.accessToken,
        passwordResetFields: e.detail.user.organisationId.passwordResetFields,
        nextLink: e.detail.nextLink
      });
    });
  }

  componentDidUpdate() {
   
    var they = this;
    if (this.props.authUser === null && !they.state.connection) {
      this.socketConnect();
    }
    
    if (this.props.userData !== null) {
      this.setState({
        userData: this.props.userData,
        remember: true,
      });
      this.props.clearUserData();
    }
    if (this.props.showMessage) {
      // var timer = setTimeout(() => {
      //   this.setState({ checkTimer: true });
      // }, 300);
      // clearTimeout(timer)
      // setTimeout(() => {
      //   this.props.hideAuthMessage();
      //   message.destroy();
      // }, 100);
    }
    if (this.props.authUser !== null) {
      this.props.history.push("/");
    }
    if (this.props.userData !== null) {
      this.setState({
        userData: this.props.userData,
      });
      this.props.clearUserData();
    }
    if (this.state.userData && this.state.userData.authMethod === "google" && this.props.userData === null && this.state.googleLogin) {
      this.setState({
        userData: null,
        googleLogin: false
      });
    }
    console.log(this.state.userData,"user Data 302")
    if(this.state.userData && this.state.userData.x509Certificate && this.state.userData.x509Certificate != ""){
      // onLoginWithMicrosoftClick
      $("#onLoginWithMicrosoftClick").trigger("click");
    }
  }

  socketConnect() {
    socket.connect();
    var they = this;
    socket.on("CLIENT_PRIVATE_KEY", (privateKey) => {
      socket.emit("SECRET_KEY");
      socket.on("SECRET_KEY", (key) => {
        rsaWrapper
          .privateDecrypt(privateKey, key)
          .then((SECRET_KEY) => {
            they.AESKey = SECRET_KEY;
            they.setState({ connection: true });
            if (window.requestIdleCallback) {
              const fingerprint = btoa(navigator.userAgent);
              console.log(fingerprint, "---", navigator.userAgent, "fingerPrint");
              // cookie.set("fngprt", fingerprint);
              cookie.set("fngprt", fingerprint, {
                secure: process.env.NODE_ENV !== "dev",
              });
              console.log(cookie.get("fngprt"), "get");

              // requestIdleCallback(function() {
              //   fingerprintjs2.get(function(components) {
              //     const fingerprint = fingerprintjs2.x64hash128(
              //       components
              //         .map(function(pair) {
              //           return pair.value;
              //         })
              //         .join(),
              //       31
              //     );
              //     cookie.set("fngprt", fingerprint);
              //   });
              // });
            } else {
              const fingerprint = btoa(navigator.userAgent);
              console.log(fingerprint, "---", navigator.userAgent, "fingerPrint");
              // cookie.set("fngprt", fingerprint);
              cookie.set("fngprt", fingerprint, {
                secure: process.env.NODE_ENV !== "dev",
              });
              console.log(cookie.get("fngprt"), "get");
              // setTimeout(function() {
              //   fingerprintjs2.get(function(components) {
              //     const fingerprint = fingerprintjs2.x64hash128(
              //       components
              //         .map(function(pair) {
              //           return pair.value;
              //         })
              //         .join(),
              //       31
              //     );
              //     cookie.set("fngprt", fingerprint);
              //   });
              // }, 500);
            }
          })
          .catch((err) => {
            console.log("error occurred ", err.name);
            console.log("error occurred code ", err.code);
          });
      });
    });
  }
  loginAs = (type) => {
    message.destroy();
    this.props.hideAuthMessage();
    this.props.hideMessage();
    var self = this;
    this.setState({ loginAs: type }, function() {
      var values = self.state.values;
      values.loginAs = type;
      self.props.userSignIn(values);
    });
  };

  handleOk = (event) => {
    const { dispatch, form } = this.props;

    const { validateFieldsAndScroll } = form;
    validateFieldsAndScroll((errors, values) => {
      if (errors) {
        return;
      }
      // dispatch({ type: 'changePassword/changePassword', payload: values })
    });
    event.preventDefault();
  };

  handleConfirmBlur = (e) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("newPassword")) {
      callback("Password mismatch");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }

    // var regularExpression  = /^[a-zA-Z0-9!@#$%^&*]$/;
    var regularExpression = /^(?=.*\d)(?=.*[!@#$%^&*._])(?=.*[a-z])(?=.*[0-9]).{4,}$/;
    if (!regularExpression.test(value)) {
      callback("password should contain at least 1 special character, number and character");
    } else {
      callback();
    }
  };

  handleSubmitReset = (e) => {
    message.destroy();
    this.props.hideMessage();
    const { accessToken, isTrainee } = this.state;
    console.log({ accessToken });
    var objectData = {};
    objectData["accessToken"] = accessToken;
    objectData["isTrainee"] = isTrainee;

    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      objectData = {...objectData,  ...values}



      if (!err && this.AESKey) {
        this.props.showAuthLoader();
        localStorage.setItem("AESKey", this.AESKey);
        aesWrapper.encryptMessage(this.AESKey, values.newPassword).then((encryptedPassword) => {
          values.password = encryptedPassword;
          objectData["newPassword"] = encryptedPassword;
          console.log({ objectData });
          // this.props.userResetPassword(objectData);
          ///password/reset

          if (isTrainee) {
            ResetPasswordModel.userTraineePasswordReset(objectData).then((response) => {
              console.log({ response });
              if (!response.error) {
                var user = response.data;
                user["accessToken"] = accessToken;
                user["trainee"] = isTrainee;
                localStorage.setItem("accessToken", user.accessToken);
                localStorage.setItem("user", JSON.stringify(user));
                this.props.userSignInSuccess(user);
              }
            });
          } else {
            ResetPasswordModel.userPasswordReset(objectData).then((response) => {
              console.log({ response });
              if (!response.error) {
                var user = response.data;
                user["accessToken"] = accessToken;
                user["trainee"] = isTrainee;
                localStorage.setItem("accessToken", user.accessToken);
                localStorage.setItem("user", JSON.stringify(user));
                this.props.userSignInSuccess(user);
              }
              // this.setState({ userInfo: response, loader: false, viewModel: false, onClickButton:true });
            });
          }
        });
      }
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const { showMessage, loader, alertMessage } = this.props; 
        const { userData} = this.state;
        console.log({userData})
    return (
      <div
        style={{
          backgroundColor: "#f9f9f9",
          // backgroundImage: "linear-gradient(180deg,#aecee1,#acb0b8)"
          backgroundImage: "linear-gradient(180deg,rgba(142,220,242,.85),#001628)",
          // backgroundImage: "url(" + require("assets/images/backgrounds/login_bg_image.png") + ")",
        }}
      >
        {this.state.checkTimer && showMessage && message.destroy()}
        {this.state.checkTimer && showMessage && alertMessage !== "" && message.error(alertMessage.toString())}

        <div className="uk-flex uk-flex-middle" >
          {/* <IconFont 
            type="icon-logo_web"
            style={{
              fontSize: "120px",
              marginTop: "0px",
              marginLeft: "45px",
            }}
          ></IconFont> */}
        <i className={("fa fa-logo-black-icon " + getSubdomain())} style={{
              // fontSize: "120px",
              marginTop: "20px",
              marginLeft: "45px",
            }}></i>
        </div>
        <div data-uk-height-viewport="offset-top: true; offset-bottom: true" className="uk-flex uk-flex-middle">
          <div className="uk-width-2-3@m uk-width-1-2@s uk-margin-auto  border-radius-6 ">
            <div
              className="uk-child-width-1-2@m uk-background-grey uk-grid-collapse"
              data-uk-grid
              style={{ borderRadius: 10, margin: "0 40px" }}
            >
              <div>
                <img
                  className="welcome-image-login"
                  src={require("assets/images/welcome_image_1.png")} 
                />
              </div>

              <div id="login_container" style={{ borderRadius: 10 }}>
                <div
                  className="uk-card-default uk-padding uk-card-small custom-login-view" 
                >
                  {loader && this.state.loader ? (
                    <div
                      style={{
                        position: "absolute",
                        left: "47.7%",
                        top: "-5%",
                        zIndex: "999",
                      }}
                    >
                      <CircularProgress />
                    </div>
                  ) : null}
                  {/* <!-- Login tab tab --> */}
                  {!this.state.resetPassword ? (
                    <>
                      {!this.state.isBothUser ? (
                        <div
                          id="login"
                          className="tabcontent tab-default-open animation: uk-animation-slide-right-medium custom-login-view-2"

                        >
                          <div className="gx-app-login-content" style={{ width: "100%" }}>
                            <h2
                              className="uk-text-bold"
                              style={{ textAlign: "end", marginBottom: "40px", color: "#000", marginRight: "10px" }}
                            >
                              {" "}
                              <IntlMessages id="app.userAuth.login" />
                            </h2>
                            {/* <p className="uk-text-muted uk-margin-remove-top uk-margin-small-bottom"> Fill blank to log your account</p>
                             */}
                            <Form className="gx-signin-form gx-form-row0">
                              {/* <div className="uk-form-label"> Email address </div> */}
                              <FormItem>
                                {getFieldDecorator("email", {
                                  initialValue: this.state.userData ? this.state.userData.email : "",
                                  rules: [
                                    {
                                      required: true,
                                      type: "email",
                                      message: "The input is not valid E-mail!",
                                    },
                                  ],
                                })(
                                  <Input
                                    className=" "
                                    placeholder="Email"
                                    onChange={this.messageDestroy}
                                    value={this.state.userData ? this.state.userData.email : ""}
                                    readOnly={this.state.userData ? true : false}
                                  />
                                )}
                              </FormItem>

                              {this.state.userData && this.state.userData.authMethod === "default" && !this.state.userData.googleIdpId && !this.state.userData.microsoftX509Certificate && !this.state.userData.x509Certificate && (
                                  <FormItem>
                                    {getFieldDecorator("password", {
                                      // initialValue: "",
                                      rules: [
                                        {
                                          required: true,
                                          message: "Please input your Password!",
                                        },
                                      ],
                                    })(
                                      <Input
                                        className="uk-input"
                                        type="password"
                                        onChange={this.messageDestroy}
                                        placeholder="Password"
                                        style={{ height: "34px !important" }}
                                      />
                                    )}
                                  </FormItem>
                              )}

                              {this.state.userData && this.state.userData.authMethod === "otp" && (
                                  <FormItem>
                                    {getFieldDecorator("otp", {
                                      initialValue: "",
                                      rules: [
                                        {
                                          required: true,
                                          message: "Please input your OTP!",
                                        },
                                      ],
                                    })(
                                      <Input
                                        className="uk-input"
                                        type="text"
                                        onChange={this.messageDestroy}
                                        placeholder="OTP"
                                        style={{ height: "34px !important" }}
                                      />
                                    )}
                                  </FormItem>
                              )}

                              <FormItem className="terms_condition_checkbox login-checkbox">
                                {getFieldDecorator("remember", {
                                  valuePropName: "checked",
                                  initialValue: this.state.remember,
                                  rules: [
                                    {
                                      required: true,
                                      message: "Please accept Terms & Condition",
                                    },
                                  ],
                                })(
                                  <Checkbox onClick={this.handleCheckBox} style={{ color: "#001628" }}>
                                    <IntlMessages id="appModule.iAccept" />
                                    {" "}
                                    <a
                                      className="gx-signup-form-forgot gx-link"
                                      href="https://aware.eccouncil.org/terms.html"
                                      target="_blank"
                                    >
                                      <IntlMessages id="appModule.termsAndCondition" />
                                    </a>
                                  </Checkbox>
                                )}
                              </FormItem>

                              {!this.state.userData && (
                                <FormItem className="btn-login">
                                  <Button
                                    id="next_btn"
                                    // type="primary"
                                    className="gx-mb-0 gx-next-btn uk-button-success"
                                    //htmlType="button"
                                    disabled={this.state.remember ? false : true}
                                    onClick={this.onNextClick}
                                    style={{
                                      backgroundColor: this.state.remember ? "#2680e8" : "",
                                      color: this.state.remember ? "#ffffff" : "",
                                      cursor: "pointer",
                                      fontWeight: "900",
                                      height: "34px !important",
                                    }}
                                  >
                                    <IntlMessages id="app.userAuth.next" />
                                  </Button>
                                </FormItem>
                              )}

                              {this.state.userData && this.state.userData.authMethod === "default" && !this.state.userData.googleIdpId && !this.state.userData.microsoftX509Certificate && !this.state.userData.x509Certificate && (
                                <div>
                                  <FormItem className="btn-login">
                                    <Button
                                      onClick={this.handleSubmit}
                                      className="gx-mb-0 gx-next-btn uk-button-success"
                                      disabled={this.state.remember ? false : true}
                                      //htmlType="button"
                                      style={{
                                        backgroundColor: this.state.remember ? "#2680e8" : "",
                                        color: this.state.remember ? "#ffffff" : "",
                                        cursor: "pointer",
                                        fontWeight: "900",
                                        height: "34px !important",
                                      }}
                                    >
                                      <IntlMessages id="app.userAuth.signIn" />
                                    </Button>
                                  </FormItem>
                                </div>
                              )}
                              {!(this.state.userData && this.state.userData.authMethod === "microsoft") && !(this.state.userData && this.state.userData.authMethod === "google") &&  !(this.state.userData && (this.state.userData.googleIdpId || this.state.userData.microsoftX509Certificate)) &&
                                <FormItem>
                                  <span className="gx-signup-form-forgot gx-link">
                                    <Link to="/forgot" style={{ color: "#000" }}>
                                      <IntlMessages id="app.userAuth.forgotPassword" />
                                    </Link>
                                  </span>
                                </FormItem>
                              }

                              {this.state.userData &&
                                this.state.userData.authMethod === "default1" &&
                                this.state.userData.authMethod.length > 1 && (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      marginBottom: "16px",
                                    }}
                                  >
                                    <span className="gx-signup-form-forgot gx-link">
                                      <IntlMessages id="app.userAuth.or" />
                                    </span>
                                  </div>
                                )}

                              {this.state.userData && (this.state.userData.authMethod === "microsoft" || this.state.userData.microsoftX509Certificate) && (
                                <FormItem>
                                  <Button
                                    type="primary"
                                    className="gx-mb-0 gx-next-btn"
                                    //htmlType="button"
                                    disabled={this.state.remember ? false : true}
                                    onClick={this.onLoginWithMicrosoftClick}
                                    style={{
                                      height: "34px !important",
                                    }}
                                  >
                                    <IntlMessages id="app.userAuth.microsoft" />
                                  </Button>
                                </FormItem>
                              )}

                              {this.state.userData && (this.state.userData.x509Certificate) && (
                                <FormItem>
                                  <Button
                                    type="primary"
                                    className="gx-mb-0 gx-next-btn"
                                    id="onLoginWithMicrosoftClick"
                                    //htmlType="button"
                                    disabled={this.state.remember ? false : true}
                                    onClick={this.onLoginWithMicrosoftClick}
                                    style={{
                                      height: "34px !important",
                                    }}
                                  >
                                    <IntlMessages id="app.userAuth.sso" />
                                  </Button>
                                </FormItem>
                              )}

                              {this.state.userData && this.state.userData.authMethod === "otp" && (
                                <FormItem>
                                  <Button
                                    type="primary"
                                    className="gx-mb-0 gx-next-btn"
                                    //htmlType="button"
                                    disabled={this.state.remember ? false : true}
                                    onClick={this.onLoginWithOtp}
                                    style={{
                                      height: "34px !important",
                                    }}
                                  >
                                    <IntlMessages id="app.userAuth.otp" />
                                  </Button>
                                </FormItem>
                              )}

                              {this.state.userData && (this.state.userData.authMethod === "google" || this.state.userData.googleIdpId) && (
                                <div
                                  style={{
                                    marginTop: "16px",
                                  }}
                                >
                                  <FormItem>
                                    <Button
                                      type="primary"
                                      className="gx-mb-0 gx-next-btn"
                                      //htmlType="button"
                                      disabled={this.state.remember ? false : true}
                                      onClick={this.onLoginWithGoogleClick}
                                      style={{
                                        height: "34px !important",
                                      }}
                                    >
                                      <IntlMessages id="app.userAuth.google" />
                                    </Button>
                                  </FormItem>
                                </div>
                              )}
                            </Form>
                          </div>
                        </div>
                      ) : (
                        <div
                          id="login"
                          className="tabcontent tab-default-open animation: uk-animation-slide-right-medium"
                        >
                          <div className="gx-app-login-content" style={{ width: "100%" }}>
                            <h2 className="uk-text-bold">Login As:</h2>
                            <div className="gx-signin-form gx-form-row0 " style={{ margin: "50px 0" }}>
                              <div style={{ backgroundColor: "#ffffff" }}>
                                <Row>
                                  <Col
                                    xl={12}
                                    lg={12}
                                    md={12}
                                    sm={24}
                                    xs={24}
                                    style={{
                                      margin: "20px 0",
                                    }}
                                  >
                                    <a
                                      className="gx-mb-0 gx-admin-btn"
                                      //htmlType="button"
                                      onClick={() => {
                                        this.loginAs("admin");
                                      }}
                                      style={{
                                        margin: "20px 0",
                                        fontWeight: "600",
                                        color: "#3e474f",
                                        textDecoration: "none",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <div
                                        className="btn-hover  uk-text-center uk-padding-small uk-animation-scale-up"
                                        style={{
                                          backgroundColor: "#ffffff",
                                          border: "solid 1px #d9d9d9",
                                        }}
                                      >
                                        <p>
                                          <IconFont type="icon-man-with-tie" style={{ fontSize: "60px" }}></IconFont>{" "}
                                        </p>
                                        Admin
                                      </div>
                                    </a>
                                  </Col>
                                  <Col
                                    xl={12}
                                    lg={12}
                                    md={12}
                                    sm={24}
                                    xs={24}
                                    style={{
                                      margin: "20px 0",
                                    }}
                                  >
                                    <a
                                      className="gx-mb-0 gx-admin-btn"
                                      //htmlType="button"
                                      onClick={() => {
                                        this.loginAs("trainee");
                                      }}
                                      style={{
                                        margin: "20px 0",
                                        fontWeight: "600",
                                        color: "#3e474f",
                                        textDecoration: "none",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <div
                                        className="btn-hover uk-text-center uk-padding-small uk-animation-scale-up"
                                        style={{
                                          backgroundColor: "#ffffff",
                                          border: "solid 1px #d9d9d9",
                                        }}
                                      >
                                        <p>
                                          <IconFont type="icon-user-shape" style={{ fontSize: "60px" }}></IconFont>{" "}
                                        </p>
                                        Trainee
                                      </div>
                                    </a>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <div
                      id="login"
                      className="tabcontent tab-default-open animation: uk-animation-slide-right-medium"
                      style={{ marginRight: "-10%" }}
                    >
                      <div className="gx-app-login-content" style={{ width: "100%" }}>
                        <h2
                          className="uk-text-bold"
                          style={{ textAlign: "end", marginBottom: "40px", color: "#000", marginRight: "10px" }}
                        >
                          {" "}
                          <IntlMessages id="app.userAuth.resetPassword" />
                        </h2>
                        <p className="uk-text-muted uk-margin-remove-top uk-margin-small-bottom">
                          {" "}
                          <IntlMessages id="app.userAuth.setYourNewPassword" />{" "}
                        </p>

                        <Form className="gx-signin-form gx-form-row0">
                          {/* <div className="uk-form-label">New Password </div> */}
                          <FormItem>
                            {getFieldDecorator("newPassword", {
                              rules: [
                                {
                                  required: true,
                                  pattern: /^(?=.*\d)(?=.*[!@#$%^&*._])(?=.*[a-z])(?=.*[0-9]).{4,}$/,
                                  message: "Please input new password!",
                                },
                                {
                                  validator: this.validateToNextPassword,
                                },
                              ],
                            })(
                              <Input
                                className="uk-input uk-form-width-large"
                                type="password"
                                onChange={this.messageDestroy}
                                onPressEnter={this.handleOk}
                                placeholder={`New Password`}
                              />
                            )}
                          </FormItem>
                          {/* <div className="uk-form-label"> Confirm Password </div> */}
                          <FormItem>
                            {getFieldDecorator("confirmPassword", {
                              rules: [
                                {
                                  required: true,
                                  message: "Please input confirm password!",
                                },
                                {
                                  validator: this.compareToFirstPassword,
                                },
                              ],
                            })(
                              <Input
                                onBlur={this.handleConfirmBlur}
                                className="uk-input uk-form-width-large"
                                type="password"
                                onChange={this.messageDestroy}
                                onPressEnter={this.handleOk}
                                placeholder={`Confirm Password`}
                              />
                            )}
                          </FormItem>
                          {
                            this.state.passwordResetFields &&
                            <>
                            {
                              this.state.passwordResetFields.map(field=>{
                                return (
                                  <FormItem>
                                    {getFieldDecorator(field.name, {
                                      rules: [
                                        {
                                          required: true,
                                          message: field.message,
                                        }
                                      ],
                                    })(
                                      <Input
                                        className="uk-input uk-form-width-large"
                                        type="text"
                                        onChange={this.messageDestroy}
                                        onPressEnter={this.handleOk}
                                        placeholder={field.title}
                                      />
                                    )}
                                  </FormItem>
                                )
                              })
                            }
                            </>
                            
                          }
                          <FormItem className="btn-login">
                            <Button
                              id="next_btn"
                              // type="primary"
                              className="gx-mb-0 gx-next-btn uk-button-success"
                              htmlType="button"
                              onClick={this.handleSubmitReset}
                            >
                              <IntlMessages id="app.userAuth.resetPassword" />
                            </Button>
                          </FormItem>
                        </Form>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {getSubdomain() != "hdfclifelearning" && getSubdomain() != "hdfcpensionlearning"  && getSubdomain() != "hdfcreinsurancelearning"&& getSubdomain() != "securiumlearning" && (
        <div className={styles.footer} style={{ textAlign: "center", padding: 20 }}>
          Copyright © All rights reserved |{" "}
          <a href="https://www.eccouncil.org/" target="_blank">
            EC-Council
          </a>
        </div>
        )}
        {/* ) : (
          <div
            data-uk-height-viewport="offset-top: true; offset-bottom: true"
            className="uk-flex uk-flex-middle "
          >
            <div className="uk-width-2-3@m uk-width-1-2@s uk-margin-auto  border-radius-6 ">
              <div style={{ backgroundColor: "#ffffff" }}>
                <Row>
                  <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <div className="uk-text-middle   uk-text-center uk-padding-small uk-animation-scale-up">
                      <p>
                        <IconFont
                          type="icon-Admin"
                          style={{ fontSize: "60px" }}
                        ></IconFont>{" "}
                      </p>

                      <Button
                        className="gx-mb-0 gx-next-btn"
                        //htmlType="button"
                        onClick={() => {
                          this.loginAs("admin");
                        }}
                        style={{ margin: "20px 0", fontWeight: "600" }}
                      >
                        Login as Admin
                      </Button>
                    </div>
                  </Col>
                  <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <div
                      className="uk-text-middle uk-text-center uk-padding-small uk-animation-scale-up"
                      style={{
                        backgroundColor: "#ffffff",
                        borderLeft: "solid 1px #d9d9d9"
                      }}
                    >
                      <p>
                        <IconFont
                          type="icon-user"
                          style={{ fontSize: "60px" }}
                        ></IconFont>{" "}
                      </p>

                      <Button
                        className="gx-mb-0 gx-next-btn"
                        //htmlType="button"
                        onClick={() => {
                          this.loginAs("trainee");
                        }}
                        style={{ margin: "20px 0", fontWeight: "600" }}
                      >
                        Login as Trainee
                      </Button>
                      <div style={{ margin: "20px 0" }}></div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        )} */}
      </div>
    );
  }
}

const WrappedNormalLoginForm = Form.create()(SignIn);

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser, userData, accessToken } = auth;
  return { loader, alertMessage, showMessage, authUser, userData, accessToken };
};

export default connect(
  mapStateToProps,
  {
    userSignIn,
    fetchUser,
    hideAuthMessage,
    hideMessage,
    showAuthLoader,
    hideAuthLoader,
    userFacebookSignIn,
    showAuthMessage,
    userGoogleSignIn,
    userGithubSignIn,
    userTwitterSignIn,
    clearUserData,
    userMicrosoftSignIn,
    userOtpSignIn,
    userResetPassword,
    userSignInSuccess,
    findUserSuccess
  }
)(WrappedNormalLoginForm);
