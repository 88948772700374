module.exports = {
    siteName: "OhPhish LMS",
    footerText: "Copyright © All rights reserved | Shield Alliance International Limited",
    apiPrefix: "http://demo2679133.mockable.io/",
    imagePrefixUrl: "/serve/",
    imageMediaUrl: (process.env.REACT_APP_API_URL || (process.env.NODE_ENV == "production" ? "https://api.ohphish.com/" :  "https://staging.ohphish.com/")).replace(/\/$/, ''), 
    apiUrl: process.env.REACT_APP_API_URL || (process.env.NODE_ENV == "production" ? "https://api.ohphish.com/" : "https://staging.ohphish.com/"), 
    socketURL: process.env.REACT_APP_API_URL || (process.env.NODE_ENV == "production" ?  "https://api.ohphish.com/":"https://staging.ohphish.com/"), 
    stripeKey: "pk_test_XdTe5pNvAopfYbbJzALPh3bk00SRyIcUms",
    api: {
      queryAuthMethod: "api/auth/method",
  
      queryUserInfo: "api/ping",
      queryUserInfoElearning: "api/elearning/ping",
      logoutUser: "/user/logout",
      queryForgotPassword: "api/lms/forgot",
      queryResetPassword: "api/reset",
      authLoginUser: "api/auth/login",
      otpLoginUser: "api/otp/auth/login",
      loginUser: "api/elearning/login/v2",
      removeUserList: "POST /users/delete",
      queryPostList: "/posts",
      queryDashboard: "api/dashboard/analytics/v1?active=true&build=true",
      queryAllCampaignTrainings: "api/trainings",
      // queryAllCampaignTrainings: "api/trainings/v1",
      //trainee api
      queryTraineeTraining: "api/lms/trainee/trainings",
  
      //training api//
      queryAllTrainings: "api/lms/training",
      queryTrainings: "api/trainings",
      importNewFileTraining: "api/training/import/v1",
      uploadMediaFileTraining: "api/training/upload_media",
      queryUpdateTraining: "api/training/update_training",
  
      //campaigns api//
      campaignsInfo: "api/campaigns",
      campaignsAnalytics: "api/campaigns/analytics",
      campaignsUsers: `api/campaigns/:campaignId/users`,
      importFile: "/import/file",
  
      //----USER API---//
  
      userProfileUpdate: "api/users/profile",
      passwordReset: "api/password/reset"
    },
    scormKey:{
      "en-us" : "English(United States)",
      "en-au" : "English(Australia)",
      "fr-CA": "Canada(French)",
      myb : "Bahasa Malaysia",
      ar : "Arabic",
      vi : "Vietnam",
      th : "Thailand",
      zh : "Chinese",
      el : "Greek",
      uk : "Ukraine",
      fr : "French",
      te : "Telgu",
      dk : "Danish",
      no : "Norwegian",
      se : "Swedish",
      hi : "Hindi",
      mk : "Macedonian",
      gu : "Gujrati",
      de : "German",
      pt : "Portuguese",
      spa : "Spaninsh"
    },
  };